import React, { useState } from 'react';
import { FaGoogle, FaApple } from 'react-icons/fa';
import { useNavigate, Outlet } from 'react-router-dom';
import NavBar from '../Home/navbar';
import waved from '../../assets/waved.svg';

const LoginForm = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState({
  
    email: '',
    password: '',
    userType: 'student',
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/user/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('Login successful! Redirecting to your dashboard...');
        localStorage.setItem('access_token', data.access_token);
        setIsLoggedIn(true);

        switch(formData.userType) {
          case 'admin':
            navigate('/AdminDashboard/AdminMainLayout');
            break;
          case 'teacher':
            navigate('/TeacherDashboard/TeacherMainLayout');
            break;
          case 'student':
            navigate('/StudentDashboard/StudentMainLayout');
            break;
          default:
            setMessage('Please select a user type.');
        }
      } else {
        setMessage(data.message || 'Login failed');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred. Please try again.');
    }
  };

  const onForgotPasswordClicked = (event) => {
    event.preventDefault();
    navigate('/ForgotPassword');
  };

  const onRegisterClicked = (event) => {
    event.preventDefault();
    navigate('/SignupForm');
  };

  return (
    <div className="min-h-screen flex flex-col" style={{
      backgroundImage: `url(${waved})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    }}>
      <NavBar />
      <div className="flex flex-col items-center justify-center flex-1 p-6">
        <h2 className="text-3xl font-bold mb-4">Login TWKSAA </h2>
        <p className="text-lg mb-6">For a Brighter Future!</p>
        {message}
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full max-w-md">
         
          <div className="mb-4">
           
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full p-3 border border-gray-300 rounded mb-3 focus:outline-none focus:ring focus:ring-blue-300"
            />
            <input
              type="password"
              name="password"
              placeholder="Enter your password"
              value={formData.password}
              onChange={handleChange}
              required
              className="w-full p-3 border border-gray-300 rounded mb-3 focus:outline-none focus:ring focus:ring-blue-300"
            />
            <select
              name="userType"
              value={formData.userType}
              onChange={handleChange}
              required
              className="w-full p-3 border border-gray-300 rounded mb-3 focus:outline-none focus:ring focus:ring-blue-300"
            >
              <option value="student">Student</option>
              <option value="teacher">Teacher</option>
              <option value="admin">Admin</option>
            </select>
          </div>
          <button type="submit" className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700 transition duration-300">
            Login
          </button>

          <button type="submit" className="w-full bg-orange-600 text-white py-2 rounded hover:bg-blue-700 transition duration-300" onClick={onForgotPasswordClicked}>
            ForgotPassword
          </button>

          <div className="flex justify-between items-center mt-6">
            <button type="button" className="flex items-center justify-center w-full bg-red-500 text-white py-2 rounded hover:bg-red-600 transition duration-300 mr-2">
              <FaGoogle className="mr-2" /> Login with Google
            </button>
            <button type="button" className="flex items-center justify-center w-full bg-gray-900 text-white py-2 rounded hover:bg-gray-800 transition duration-300 ml-2">
              <FaApple className="mr-2" /> Login with Apple
            </button>
          </div>
          

          {isLoggedIn && (
          <button
            onClick={() => navigate('/GetProfile')}
            className="mt-4 w-full bg-green-600 text-white py-2 rounded hover:bg-green-700 transition duration-300"
          >
            Redirect
          </button>
        )}

        <div className="mt-6 text-center">
          <p>Don't have an account?</p>
          <a href="#" className="text-blue-500 hover:underline" onClick={onRegisterClicked}>Register here</a>
        </div>


        </form>
      </div>
      <Outlet />
    </div>
  );
};

export default LoginForm;
