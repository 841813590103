import React, { useState } from 'react';
import LiveclassNameItem from './LiveClassItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'; 

const StudentLiveclass = () => {
  const [StudentLiveclass] = useState([
    {
      title: 'React Basics',
      startTime: '9:00 PM',
      isLive: true,
      date: '10-08-2024',
    },
    {
      title: 'Tailwind CSS',
      startTime: '4:00 PM',
      isLive: false,
      date: '11/08/2024',
    },
    {
      title: 'JS ES6 Features',
      startTime: '2:00 PM',
      isLive: false,
      date: '12/08/2024',
    },
    {
      title: 'Intro to TypeScript',
      startTime: '8:00 PM',
      isLive: false,
      date: '13/08/2024',
    },
    {
      title: 'GraphQL Basics',
      startTime: '6:00 PM',
      isLive: false,
      date: '15/08/2024',
    },
  ]);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="flex flex-col md:flex-row">

      <button
        className="md:hidden fixed top-4 left-4 z-50 p-2 bg-gray-500 text-white rounded"
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        <FontAwesomeIcon icon={faBars} className="w-6 h-6" />
      </button>

      <div
        className={`flex-1 bg-gray-50 p-4 md:p-6 transition-transform duration-300 ease-in-out ${sidebarOpen ? 'md:ml-64' : ''} overflow-y-auto h-screen`}
      >
        <div className="relative mb-8">
          <h2 className="text-2xl md:text-3xl font-bold">Live classNamees</h2>
        </div>
        <div className="space-y-4">
          {StudentLiveclass.map((liveclassName, index) => (
            <LiveclassNameItem
              key={index}
              title={liveclassName.title}
              startTime={liveclassName.startTime}
              isLive={liveclassName.isLive}
              date={liveclassName.date}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default StudentLiveclass;
