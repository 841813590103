import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DonarRecords from './components/DonationManagement/DonarRecords';
import Courses from './components/courseManagement/CourseManagement';
import Organization from './components/organizationmanagement/Organization';
import StudentProfile from './components/userManagement/studentMgm/Student';
import AdminMainLayout from './components/layout/AdminMainLayout'
import AdminDashboard from './components/AdminDashboard/adminDashboard';
function AdminPanel() {
    return (

        <Routes>
            <Route path='AdminMainLayout' element={<AdminMainLayout />}>
                <Route index element={<AdminDashboard />} />
                <Route path="AdminUserMgn" element={<StudentProfile />} />
                <Route path="AdminCourseMgn" element={<Courses />} />
                <Route path="AdminDonationMgn" element={<DonarRecords />} />
                <Route path="AdminOrgMgn" element={<Organization />} />
                <Route path="AdminDashboard" element={<AdminDashboard />} />
            </Route>
        </Routes>


    );
}

export default AdminPanel;