import React from "react";

const ProfessionalInfo = () => {
  // Function to copy Teacher ID to the clipboard
  const copyToClipboard = () => {
    const teacherId = "UT101302002";
    navigator.clipboard.writeText(teacherId)
      .then(() => {
        alert("Teacher ID copied to clipboard!");
      })
      .catch(() => {
        alert("Failed to copy Teacher ID.");
      });
  };

  return (
    <section className="mt-5 bg-white p-4 rounded-lg shadow-lg">
      <h3 className="text-lg font-semibold">Professional Information</h3>
      <div className="flex flex-col space-y-2 gap-7 md:space-y-0 mt-3">
        <div className="flex-1">
          <p>Teacher ID</p>
          <span className="bg-green-200 px-3 py-1 rounded-full flex items-center justify-between">
            UT101302002
            <span onClick={copyToClipboard} className="cursor-pointer ml-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
                <path fill="currentColor" d="M9 18q-.825 0-1.412-.587T7 16V4q0-.825.588-1.412T9 2h9q.825 0 1.413.588T20 4v12q0 .825-.587 1.413T18 18zm0-2h9V4H9zm-4 6q-.825 0-1.412-.587T3 20V7q0-.425.288-.712T4 6t.713.288T5 7v13h10q.425 0 .713.288T16 21t-.288.713T15 22zm4-6V4z"/>
              </svg>
            </span>
          </span>
        </div>
        <div className="flex-1">
          <p>Specialization</p>
          <div className="flex space-x-2">
            <span className="bg-green-500 text-white px-3 py-1 rounded-full">
              Game Theory
            </span>
            <span className="bg-green-500 text-white px-3 py-1 rounded-full">
              Machine Learning
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProfessionalInfo;
