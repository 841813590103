import React from 'react';
import { Routes, Route } from 'react-router-dom';
import StudentProfile from './components/StudentProfile/StudentProfile';
import StudentCourses from './components/Courses/Courses'
import StudentLiveClass from './components/LiveClasses/LiveClasses'
import StudentClassSchedule from './components/classSchedule/ClassSchedule'
import StudentAssignment from './components/Assignments/Assignments'
import StudentSupport from './components/Support/Support'
import StudentMainLayout from './components/layout/StudentMainLayout';
import StudentDashboard from './components/StudentDashboard/studentDashboard';


function StudentPanel() {
    return (

        <Routes>
            <Route path="StudentMainLayout" element={<StudentMainLayout />} >
                <Route index element={<StudentDashboard />} />
                <Route path="StudentProfile" element={<StudentProfile />} />
                <Route path="StudentCourses" element={<StudentCourses />} />
                <Route path="StudentLiveClass" element={<StudentLiveClass />} />
                <Route path="StudentClassSchedule" element={<StudentClassSchedule />} />
                <Route path="StudentAssignment" element={<StudentAssignment />} />
                <Route path="StudentSupport" element={<StudentSupport />} />
                <Route path="StudentDashboard" element={<StudentDashboard />} />
            </ Route>
        </Routes >


    );
}

export default StudentPanel;




