import React, { useState } from 'react';
import { useCourseContext } from '../Context/CreateContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const StudentProfile = () => {
  const { enrolledCourses, removeCourse } = useCourseContext();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const student = {
    name: 'Rajkumar Rao',
    email: 'rajkumar.rao@example.com',
    dob: '01/01/2000',
    mobile: '+1234567890',
    img: 'https://media.istockphoto.com/id/1448069480/photo/happy-young-indian-student-boy-working-on-laptop-and-studying-in-college-library-with.jpg?s=612x612&w=is&k=20&c=vmoZQk5IhLV9It9H3yXJeH_bq11BnC14NwPmjZMhfWA=',
  };

  const achievements = [
    { title: 'Best Student Award', date: 'June 2024' },
    { title: 'Top Performer in Javascript', date: 'December 2023' },
    { title: 'Marathon Winner', date: 'March 2023' },
  ];

  const handleDelete = (course) => {
    removeCourse(course);
  };

  return (
    <div className="flex flex-col md:flex-row">

      <button
        className="md:hidden fixed top-4 left-4 z-50 p-2 bg-gray-500 text-white rounded"
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        <FontAwesomeIcon icon={faBars} className="w-6 h-6" />
      </button>

      <div className={`flex-1 p-4 md:p-8 bg-gray-200 transition-transform duration-300 ease-in-out ${sidebarOpen ? 'md:ml-64' : ''} overflow-y-auto h-screen`}>
        <div className="relative mb-8 md:mb-12">
          <h1 className="text-2xl md:text-3xl font-bold">Student Profile</h1>
        </div>
        <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-6">
          <img
            src={student.img}
            alt="Profile"
            className="w-32 h-32 object-cover rounded-full border-4 border-blue-500"
          />
          <div>
            <p className="text-base md:text-lg text-gray-700">
              Name: <span className="font-semibold">{student.name}</span>
            </p>
            <p className="text-base md:text-lg text-gray-700">
              Email: <span className="font-semibold">{student.email}</span>
            </p>
            <p className="text-base md:text-lg text-gray-700">
              Date of Birth: <span className="font-semibold">{student.dob}</span>
            </p>
            <p className="text-base md:text-lg text-gray-700">
              Mobile: <span className="font-semibold">{student.mobile}</span>
            </p>
          </div>
        </div>

        <div className="space-y-4 mt-4 md:mt-8">
          {enrolledCourses.length > 0 ? (
            enrolledCourses.map((course, index) => (
              <div key={index} className="p-4 border border-gray-300 bg-white rounded-md flex flex-col md:flex-row justify-between items-center">
                <div>
                  <h3 className="text-lg md:text-xl font-semibold">{course.title}</h3>
                  <p className="text-gray-700">Instructor: {course.tutor}</p>
                  <p className="text-gray-700">Duration: {course.hours}</p>
                  <p className="text-gray-700">{course.description}</p>
                </div>
                <button
                  onClick={() => handleDelete(course)}
                  className="border text-white bg-red-700 p-2 mt-3 md:mt-0 rounded font-bold"
                >
                  Delete
                </button>
              </div>
            ))
          ) : (
            <p className="flex justify-center font-bold border border-blue-600 rounded my-8 py-2 text-xl md:text-2xl bg-blue-300">
              No courses enrolled yet.
            </p>
          )}
        </div>

        <div className="mt-8">
          <h2 className="text-xl md:text-2xl font-bold mb-4">Achievements</h2>
          <div className="space-y-4">
            {achievements.map((achievement, index) => (
              <div key={index} className="p-4 border border-gray-300 bg-white rounded-md flex flex-col md:flex-row justify-between">
                <h3 className="text-lg md:text-xl font-semibold">{achievement.title}</h3>
                <p className="text-gray-700">Date: {achievement.date}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentProfile;
