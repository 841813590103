import React from 'react';
import { FaFacebook, FaTwitter, FaLinkedin, FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import { Outlet } from 'react-router-dom';
import NavBar from './navbar';
import waved from '../../assets/waved.svg';

const Main = () => {
  return (
    <div className="min-h-screen flex flex-col" style={{
      backgroundImage: `url(${waved})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    }}>
      <NavBar />
      <header className="bg-blue-600 text-white py-8">
        <div className="container mx-auto text-center">
          <h1 className="text-4xl font-bold">TWKSAA WELFARE FOUNDATION</h1>
        </div>
      </header>

      <section className="py-8">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-semibold mb-4">Welcome to TWKSAA Welfare Foundation</h2>
          <p className="text-lg text-gray-700 max-w-2xl mx-auto">
            Welcome to the TWKSAA Welfare Foundation, dedicated to uplifting communities through education and support services. Our mission is to create a positive impact by providing essential resources and fostering partnerships. Join us in making a difference and building a brighter future for all.
          </p>
        </div>
      </section>

      <section className="py-8" >
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-semibold mb-4">Mission of TWF</h2>
          <p className="text-lg text-gray-700 max-w-2xl mx-auto">
            TWF TWKSAA Welfare Foundation is an organization dedicated to ensuring that future discoveries, publications, and uses of new concepts and technologies on IR4.0, RMS & TLI originate from Indian culture, civilization, and language. Our mission is to lead these advancements from the sacred land of India.
          </p>
        </div>
      </section>

      <section className=" py-8">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-semibold mb-4">Founder's Vision</h2>
          <img src="founder.jpg" alt="Founder Er Rajesh Prasad" className="mx-auto rounded-full w-48 h-48 mb-4" />
          <p className="text-lg italic text-gray-700 max-w-2xl mx-auto mb-2">
            "I, Rajesh Prasad, founded TWF to address the challenges faced by our country, society, and people through the innovative application of IR4.0, RMS & TLI."
          </p>
          <p className="text-lg font-bold text-gray-900">Founder: Er Rajesh Prasad</p>
          <div className="flex justify-center space-x-4 mt-4">
            <FaFacebook className="text-blue-600 hover:text-blue-800" />
            <FaTwitter className="text-blue-400 hover:text-blue-600" />
            <FaLinkedin className="text-blue-700 hover:text-blue-900" />
          </div>
        </div>
      </section>

      <section className="py-8">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-semibold mb-4">Call to Action</h2>
          <p className="text-lg text-gray-700 max-w-2xl mx-auto">
            I invite all students, teachers, and intellectuals passionate about research, innovation, and discovery to join TWF. Together, we can solve societal problems by leveraging our intellect, wisdom, and talents to create and adopt new solutions (IR4.0, RMS & TLI).
          </p>
        </div>
      </section>

      <footer className="bg-blue-600 text-white py-8">
        <div className="container mx-auto text-center">
          <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
          <p className="mb-2"><FaMapMarkerAlt className="inline-block mr-2" /> C/o Mr. Rajesh Prasad, Madan Tole, Kareli, Narsinghpur, Madhya Pradesh, India 487221</p>
          <p className="mb-2"><FaEnvelope className="inline-block mr-2" /> twf@twf.org</p>
          <p className="mb-2"><FaPhone className="inline-block mr-2" /> +91-9876543210</p>
        </div>
      </footer>
      <Outlet />
    </div>
  );
};

export default Main;
