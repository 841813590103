import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBook, faChalkboardTeacher, faCalendarAlt, faClipboardList, faHeadset, faHouse } from '@fortawesome/free-solid-svg-icons';

const Sidebar = () => {
  const navigate = useNavigate();

  const handleLogoutBtn = () => {
    navigate('/'); // Redirect to the home page
  };

  // Reusable Navigation Link Component
  const NavItem = ({ to, label, icon }) => (
    <li className="mb-3">
      <NavLink
        to={to}
        className={({ isActive }) =>
          `flex items-center p-2 border rounded-md ${isActive ? 'bg-blue-800' : 'bg-blue-500'} text-white font-semibold`
        }
      >
        <FontAwesomeIcon icon={icon} className="mr-2" />
        {label}
      </NavLink>
    </li>
  );

  return (
    <div className="w-64 h-screen bg-blue-900 text-white p-4">
      <h2 className="text-xl font-bold mb-4">Student Portal</h2>
      <ul>
      <NavItem to="StudentDashboard" label="Dashboard" icon={faHouse} />
        <NavItem to="StudentProfile" label="Profile" icon={faUser} />
        <NavItem to="StudentCourses" label="Courses" icon={faBook} />
        <NavItem to="StudentLiveClass" label="Live Classes" icon={faChalkboardTeacher} />
        <NavItem to="StudentClassSchedule" label="Class Schedule" icon={faCalendarAlt} />
        <NavItem to="StudentAssignment" label="Assignments" icon={faClipboardList} />
        <NavItem to="StudentSupport" label="Support" icon={faHeadset} />
      </ul>

      <div className="flex py-5">
        <button
          onClick={handleLogoutBtn}
          className="w-full border p-2 rounded bg-orange-400 text-white font-bold hover:bg-orange-700"
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
