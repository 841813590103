import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Profile from './components/TeacherProfile/Profile.jsx'
import CourseManagement from './components/TeacherCourseMgm/CourseManagement.jsx'
import StudentManagement from './components/TeacherStudentMgm/StudentManagement.jsx'
import AssignmentsExams from './components/TeacherAsgExams/AssignmentsExams.jsx'
import TeacherMainLayout from './components/layout/MainLayout.jsx';
import TeacherDashboard from './components/TeacherDashboard/teacherDashboard.jsx';


function TeacherPanel() {
    return (

        <Routes>
            <Route path="TeacherMainLayout" element={<TeacherMainLayout />}>
                <Route index element={<TeacherDashboard />} />
                <Route path="TeacherProfile" element={<Profile />} />
                <Route path="TeacherCourses" element={<CourseManagement />} />
                <Route path="TeacherStudents" element={<StudentManagement />} />
                <Route path="TeacherAssignmentExams" element={<AssignmentsExams />} />
                <Route path="TeacherDashboard" element={<TeacherDashboard />} />

            </Route>
        </Routes>

    );
}

export default TeacherPanel;