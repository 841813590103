import React from 'react';
import { CalendarIcon } from '@heroicons/react/outline';

const LiveclassNameItem = ({ title, startTime, date, isLive }) => {
    return (
        <div
            className={`p-4 mb-4 border rounded-lg flex justify-between items-center ${isLive ? 'bg-blue-100 border-blue-300' : 'bg-gray-100 border-gray-300'
                }`}
        >
            <div className="flex-1">
                <h3 className="text-lg font-semibold">{title}</h3>
                <p className="text-gray-500">Start Time: {startTime}</p>
            </div>
            {isLive ? (
                <span className="inline-block px-3 py-2 text-xs font-bold text-white cursor-pointer bg-green-600 rounded">
                    Live Now
                </span>
            ) : (
                <div className='flex justify-between items-center gap-1'>
                    <CalendarIcon className="w-5 text-white h-5 bg-gray-400 rounded-full p-[2px]" />
                    <p className='text-sm font-normal text-gray-500'>{date}</p>
                </div>
            )}
        </div>
    );
};

export default LiveclassNameItem;
