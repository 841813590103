import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { FiEdit3 } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { IoShareSocialOutline } from "react-icons/io5";

const CourseMaterials = () => {
  const [courses, setCourses] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // Load courses from sessionStorage when the component mounts
  useEffect(() => {
    const storedCourses = JSON.parse(sessionStorage.getItem("courses")) || [];
    setCourses(storedCourses);
  }, []);

  // Filter courses based on the search term
  const filteredCourses = courses.filter(
    (course) =>
      course.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      course.date.toLowerCase().includes(searchTerm.toLowerCase()) ||
      course.file.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="bg-blue-700 p-4 rounded-lg">
      {/* Search and Filter Section */}
      <div className="flex items-center mb-6">
        <input
          type="text"
          placeholder="Course name, date, CID etc."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full px-4 py-2 border rounded-xl focus:outline-none"
        />
        <div>
          <FaSearch className="ml-2 text-white" />
        </div>
      </div>

      {/* Course List */}
      <div className="space-y-4">
        {filteredCourses.length > 0 ? (
          filteredCourses.map((course, index) => (
            <div
              key={index}
              className="flex justify-between items-center bg-white text-blue-800 p-4 rounded-lg shadow-md"
            >
              {/* Course Info */}
              <div>
                <h3 className="font-semibold">{course.name}</h3>
                <p>Starting Date: {course.date}</p>
                <p>Level: {course.level}</p>
                <p>Material: {course.file}</p>
                <hr className="border-t-2 border-blue-400 mt-1" />
              </div>

              {/* Course Icons */}
              <div className="flex space-x-4 items-center">
                <div className="flex items-center space-x-1">
                  <FiEdit3 />
                </div>
                <div className="flex items-center space-x-1">
                  <MdDeleteOutline />
                </div>
                <div className="flex items-center space-x-1">
                  <IoShareSocialOutline />
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-white">No courses found</p>
        )}
      </div>

      {/* Bottom Divider */}
      <div className="flex justify-center mt-6">
        <hr className="border-t-2 border-white w-24" />
      </div>
    </div>
  );
};

export default CourseMaterials;
