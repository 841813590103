import React, { useState } from "react";

// Different card types for different sections
const ResearchCard = ({ title, publicationDate, author, doiLink }) => (
  <div className="p-4 bg-white shadow-md rounded-lg">
    <div className="bg-blue-500 text-white p-4 rounded-lg flex justify-between items-center">
      <h4 className="text-sm font-bold">{title}</h4>
      <a
        href={doiLink}
        target="_blank"
        rel="noopener noreferrer"
        className="text-white hover:underline"
      >
        {/* External link icon */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 256 256"
        >
          <path
            fill="currentColor"
            d="m232.49 112.49l-48 48a12 12 0 0 1-17-17L195 116h-30a84 84 0 0 0-81.36 63a12 12 0 1 1-23.24-6A107.94 107.94 0 0 1 165 92h30l-27.49-27.52a12 12 0 0 1 17-17l48 48a12 12 0 0 1-.02 17.01M192 204H44V88a12 12 0 0 0-24 0v128a12 12 0 0 0 12 12h160a12 12 0 0 0 0-24"
          />
        </svg>
      </a>
    </div>
    <div className="p-4">
      <p className="text-sm mb-2">
        <strong>Publication Date:</strong> {publicationDate}
      </p>
      <p className="text-sm mb-2">
        <strong>Author:</strong> {author}
      </p>
      <p className="text-sm">
        <strong>DOI:</strong>{" "}
        <a href={doiLink} className="text-blue-600 hover:underline">
          {doiLink}
        </a>
      </p>
    </div>
  </div>
);

const ProjectCard = ({ projectTitle, duration, role, collaboration }) => (
  <div className="p-4 bg-white shadow-md rounded-lg">
    <div className="bg-blue-500 text-white p-4 rounded-lg  flex justify-between items-center">
      <h4 className="text-sm font-bold">{projectTitle}</h4>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 256 256"
      >
        <path
          fill="currentColor"
          d="m232.49 112.49l-48 48a12 12 0 0 1-17-17L195 116h-30a84 84 0 0 0-81.36 63a12 12 0 1 1-23.24-6A107.94 107.94 0 0 1 165 92h30l-27.49-27.52a12 12 0 0 1 17-17l48 48a12 12 0 0 1-.02 17.01M192 204H44V88a12 12 0 0 0-24 0v128a12 12 0 0 0 12 12h160a12 12 0 0 0 0-24"
        />
      </svg>
    </div>
    <div className="p-4">
      <p className="text-sm mb-2">
        <strong>Duration:</strong> {duration}
      </p>
      <p className="text-sm mb-2">
        <strong>Role:</strong> {role}
      </p>
      <p className="text-sm">
        <strong>Collaboration:</strong> {collaboration}
      </p>
    </div>
  </div>
);

const ApprovalCard = ({ title, funding, approvedBy, description, date }) => (
  <div className="p-4 bg-white shadow-md rounded-lg">
    <div className="bg-blue-500 text-white p-4 rounded-lg ">
      <h4 className="text-sm font-bold">{title}</h4>
      <p className="text-sm">
        <strong>Funding:</strong> {funding}
      </p>
    </div>
    <div className="p-4">
      <p className="text-sm mb-2">
        <strong>Approved By:</strong> {approvedBy}
      </p>
      <p className="text-sm mb-2">
        <strong>Description:</strong> {description}
      </p>
      <p className="text-sm">
        <strong>Date:</strong> {date}
      </p>
    </div>
  </div>
);

const ConferenceCard = ({ title, date, location, description, outcome }) => (
  <div className="p-4 bg-white shadow-md rounded-lg">
    <div className="bg-blue-500 text-white p-4 rounded-lg">
      <h4 className="text-sm font-bold">{title}</h4>
      <p className="text-sm">
        <strong>Date:</strong> {date}
      </p>
    </div>
    <div className="p-4">
      <p className="text-sm mb-2">
        <strong>Location:</strong> {location}
      </p>
      <p className="text-sm mb-2">
        <strong>Description:</strong> {description}
      </p>
      <p className="text-sm">
        <strong>Outcome:</strong> {outcome}
      </p>
    </div>
  </div>
);

const AcademicContributions = () => {
  // State to track the active section
  const [activeSection, setActiveSection] = useState("Research");

  // Handle button click
  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  return (
    <section className="mt-5 bg-white p-4 rounded-lg shadow-lg">
      <h3 className="text-lg font-semibold">Academic Contributions</h3>

      {/* Button navigation */}
      <div className="flex flex-wrap mt-3">
        <button
          className={`px-4 py-2 rounded-lg mr-2 ${
            activeSection === "Research"
              ? "bg-green-500 text-white"
              : "border hover:bg-blue-300 "
          }`}
          onClick={() => handleSectionChange("Research")}
        >
          Research
        </button>
        <button
          className={`px-4 py-2 rounded-lg mr-2  ${
            activeSection === "Projects"
              ? "bg-green-500 text-white"
              : "border hover:bg-blue-300"
          }`}
          onClick={() => handleSectionChange("Projects")}
        >
          Projects
        </button>
        <button
          className={`px-4 py-2 rounded-lg mr-2 ${
            activeSection === "Approval"
              ? "bg-green-500 text-white"
              : "border hover:bg-blue-300"
          }`}
          onClick={() => handleSectionChange("Approval")}
        >
          Approval
        </button>
        <button
          className={`px-4 py-2 rounded-lg ${
            activeSection === "Conference"
              ? "bg-green-500 text-white"
              : "border hover:bg-blue-300"
          }`}
          onClick={() => handleSectionChange("Conference")}
        >
          Conference
        </button>
      </div>

      {/* Display cards based on active section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-5">
        {/* Research Section */}
        {activeSection === "Research" && (
          <>
            <ResearchCard
              title="Effect of Sunlight on Human Cognitive Functioning"
              publicationDate="30 Jul 2023"
              author="Dr. Anjan, Et. Vijaya Mistry"
              doiLink="https://doi.org/ABC.BBB.CC"
            />
            <ResearchCard
              title="Advances in Neuroscience Research"
              publicationDate="15 Jan 2024"
              author="Dr. Emily White"
              doiLink="https://doi.org/XYZ.123.456"
            />
          </>
        )}

        {/* Projects Section */}
        {activeSection === "Projects" && (
          <>
            <ProjectCard
              projectTitle="A New Approach to AI-based Robotics"
              duration="12 months"
              role="Lead Developer"
              collaboration="In collaboration with XYZ University"
            />
            <ProjectCard
              projectTitle="AI in Healthcare Innovation"
              duration="18 months"
              role="Research Analyst"
              collaboration="In collaboration with ABC Corp"
            />
          </>
        )}

        {/* Approval Section */}
        {activeSection === "Approval" && (
          <>
            <ApprovalCard
              title="Approval for Cognitive Science Research"
              funding="$100,000"
              approvedBy="National Science Foundation"
              description="This research is aimed at understanding the effects of cognitive load in various environments."
              date="12 Oct 2022"
            />
            <ApprovalCard
              title="AI Robotics Research Funding"
              funding="$200,000"
              approvedBy="Robotics Research Institute"
              description="This project explores the development of AI-driven robots."
              date="5 Nov 2023"
            />
          </>
        )}

        {/* Conference Section */}
        {activeSection === "Conference" && (
          <>
            <ConferenceCard
              title="AI in Education: Conference 2022"
              date="10 Aug 2022"
              location="New York, USA"
              description="The conference focused on integrating AI into educational practices."
              outcome="Promising collaborations established for future research."
            />
            <ConferenceCard
              title="Blockchain and AI Symposium"
              date="21 Dec 2023"
              location="San Francisco, USA"
              description="The symposium explored the intersection of blockchain and AI technologies."
              outcome="New research opportunities and partnerships formed."
            />
          </>
        )}
      </div>
    </section>
  );
};

export default AcademicContributions;
