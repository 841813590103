import React, { useState } from 'react';
import axios from 'axios';
import './Resetpwd.css';
import { useLocation } from 'react-router-dom';
import NavBar from '../../Home/navbar';

const ResetPWD = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setError("Passwords do not match");
            return;
        }

        const data = JSON.stringify({
            newPassword: newPassword
        });

        const config = {
            method: 'post',
            maxBodyLength: Infinity,

            url: `${process.env.REACT_APP_URL}/user/reset-password?token=${token}`,

            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                setSuccess('Password reset successfully');
                setError('');
            })
            .catch((error) => {
                console.log(error);
                setError('Failed to reset password');
                setSuccess('');
            });
    };

    return (
        <> 
         <NavBar/>
        <div className='reset-password'>
           
            <form onSubmit={handleSubmit}>
                <label>
                    New Password:
                    <input
                        type="password"
                        name="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                </label>
                <label>
                    Confirm Password:
                    <input
                        type="password"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </label>
                <button type="submit">Reset Password</button>
                {error && <p className="error-message">{error}</p>}
                {success && <p className="success-message">{success}</p>}
            </form>
        </div>
        </>

    );
};

export default ResetPWD;
//test