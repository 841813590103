import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';

const StudentMainLayout = () => {
  return (
    <div className="min-h-screen flex flex-row">
      <Sidebar />
      <main className="flex-1 p-5 bg-blue-50">
        <Outlet />
      </main>
    </div>
  );
};

export default StudentMainLayout;
