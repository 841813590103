import React from "react";

const StudentDashboard = () => {
  return (
    <div className="min-h-screen flex">
     <h1> Dashboard </h1>
    </div>
  );
};

export default StudentDashboard;