import React from "react";

const TeacherDashboard = () => {
  return (
    <div className="min-h-screen flex">
     <h1> Dashboard </h1>
    </div>
  );
};

export default TeacherDashboard;