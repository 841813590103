import React, { useState } from 'react';
import axios from 'axios';
import NavBar from '../../Home/navbar';

const ResetPassword = () => {
  const [email, setEmail] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSendEmail = async () => {
    let data = JSON.stringify({ email });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_URL}/user/request-reset-password`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data: data
    };

    try {
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      alert('Email sent successfully');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Error sending email');
    }
  };

  return (
    <>
      <NavBar />
      <div className='reset-password'>
        <div className='reset-form'>
          <input type="email" value={email} onChange={handleEmailChange} placeholder="Enter your email" required />
          <button type="button" onClick={handleSendEmail}>Send</button>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
