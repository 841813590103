import React from 'react';
import Header from './Header';
import ProfessionalInfo from './ProfessionalInfo';
import AcademicContributions from './AcademicContributions';

const Profile = () => {
  return (
    <div>
      <Header />
      <ProfessionalInfo />
      <AcademicContributions />
    </div>
  );
};

export default Profile;
