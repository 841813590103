import React from "react";

const Header = () => {
  return (
    <section className="bg-blue-700 text-white p-4 rounded-lg flex justify-between items-center">
      <div>
        <h2 className="text-xl">Raj Kumar</h2>
        <p>Email: example@gmail.com</p>
        <p>Phone: 1234567890</p>
      </div>
      <div>
        <p>DOB: 17 Jul</p>
        <button className="bg-blue-600 px-4 py-1 rounded">Edit details</button>
      </div>
    </section>
  );
};

export default Header;
