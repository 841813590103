import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBook, faChalkboardTeacher, faCalendarAlt, faHouse } from '@fortawesome/free-solid-svg-icons';

const Sidebar = () => {
  const navigate = useNavigate();

  const handleLogoutBtn = () => {
    navigate('/'); // Redirect to the home page
  };

  // Reusable Navigation Link Component
  const NavItem = ({ to, label, icon }) => (
    <li className="mb-3">
      <NavLink
        to={to}
        className={({ isActive }) =>
          `flex items-center p-2 border rounded-md ${isActive ? 'bg-blue-800' : 'bg-blue-500'} text-white font-semibold`
        }
      >
        <FontAwesomeIcon icon={icon} className="mr-2" />
        {label}
      </NavLink>
    </li>
  );

  return (
    <div className="w-64 h-screen bg-blue-900 text-white p-4">
      <h2 className="text-xl font-bold mb-4">Admin Dashboard</h2>
      <ul>
        <NavItem to="AdminDashboard" label="Dashboard" icon={faHouse} />
        <NavItem to="AdminUserMgn" label="User Management" icon={faUser} />
        <NavItem to="AdminCourseMgn" label="Course Management" icon={faBook} />
        <NavItem to="AdminDonationMgn" label="Donation Management" icon={faChalkboardTeacher} />
        <NavItem to="AdminOrgMgn" label="Organization Management" icon={faCalendarAlt} />
      </ul>

      <div className="flex py-5">
        <button
          onClick={handleLogoutBtn}
          className="w-full border p-2 rounded bg-orange-400 text-white font-bold hover:bg-orange-700"
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
