
import React, { useState } from "react";
import { FaPen, FaPlus, FaCalendarAlt, FaClipboardList } from "react-icons/fa";

const Assignments = () => {
  const [instructions, setInstructions] = useState([
    "Create a new Rust project using cargo.",
  ]);
  const [newInstruction, setNewInstruction] = useState("");
  const [title, setTitle] = useState(
    "Get familiar with the basics of Rust programming language"
  );

  const handleAddInstruction = () => {
    if (newInstruction) {
      setInstructions([...instructions, newInstruction]);
      setNewInstruction("");
    }
  };

  const handleRemoveInstruction = (index) => {
    const updatedInstructions = instructions.filter((_, i) => i !== index);
    setInstructions(updatedInstructions);
  };

  return (
    <div className="bg-blue-600 p-6 rounded-lg mt-7 text-white space-y-4">
      {/* Title and Description */}
      <div className="mb-10">
        <label className="block mb-2 font-semibold">
          Title and Description :
        </label>
        <div className="bg-blue-800 p-2 rounded-lg flex items-center">
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)} // Update title on input change
            className="bg-transparent flex-grow text-white focus:outline-none"
          />
          <FaPen className="ml-2 text-white" />
        </div>
      </div>

      <hr className="bg-gray-400" />

      {/* Instructions Section */}
      <div>
        <div className="space-y-2 mt-10 mb-10">
          <label className="block mb-2 font-semibold">Instructions</label>
          <div className="space-y-2">
            {instructions.map((instruction, index) => (
              <div
                key={index}
                className="bg-white text-blue-800 p-2 rounded-lg flex justify-between items-center"
              >
                <span>{instruction}</span>
                <button
                  className="text-red-600"
                  onClick={() => handleRemoveInstruction(index)}
                >
                  ✖
                </button>
              </div>
            ))}
            <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2">
              <input
                type="text"
                value={newInstruction}
                onChange={(e) => setNewInstruction(e.target.value)}
                placeholder="Add instruction"
                className="flex-grow p-2 rounded-lg focus:outline-none text-blue-800"
              />
              <button
                className="bg-blue-800 p-2 rounded-lg text-white"
                onClick={handleAddInstruction}
              >
                <FaPlus />
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr className="bg-gray-400" />

      {/* Upload Files Section */}
      <div>
        <div className="flex flex-col md:flex-row justify-around md:justify-between items-center mt-10 mb-10 bg-blue-800 p-6 rounded-lg space-y-6 md:space-y-0">
          <div className="flex flex-col items-center w-full md:w-1/2 gap-6">
            <div className="bg-gray-200 p-4 rounded-full">
              <FaClipboardList className="text-blue-800" />
            </div>
            <button className="bg-blue-600 text-white px-8 py-2 rounded-lg w-full sm:w-auto">
              Upload files
            </button>
          </div>
          <div className="space-y-4 flex flex-col w-full md:w-1/2   items-center md:items-center gap-3">
            <button className="bg-gray-200 text-blue-800 px-4 py-2 rounded-lg flex items-center space-x-2 w-full sm:w-auto">
              <FaCalendarAlt />
              <span>Due Date</span>
            </button>
            <button className="bg-gray-200 text-blue-800 px-4 py-2 rounded-lg flex items-center space-x-2 w-full sm:w-auto">
              <FaClipboardList />
              <span>Criteria</span>
            </button>
          </div>
        </div>
      </div>

      <hr className="bg-gray-400" />

      {/* Continue Button */}
      <div className="w-full flex items-center justify-center">
        <button className="bg-green-500 text-white py-2 mt-20 rounded-lg px-6 sm:px-28 hover:bg-green-600 w-full sm:w-auto">
          Continue
        </button>
      </div>
    </div>
  );
};

export default Assignments;
