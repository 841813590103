import React, { useState, useEffect } from "react";
import {
  FaEdit,
  FaTrash,
  FaFileUpload,
  FaPen,
} from "react-icons/fa";

const CreateEditCourses = ({ addCourse }) => {
  const [courseName, setCourseName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [level, setLevel] = useState("Easy");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [courses, setCourses] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null); // Track the index of the course being edited

  // Load courses from sessionStorage when the component mounts
  useEffect(() => {
    const storedCourses = JSON.parse(sessionStorage.getItem("courses")) || [];
    setCourses(storedCourses);
  }, []);

  const handleFileChange = (e) => {
    setUploadedFile(e.target.files[0]);
  };

  const handleAddCourse = () => {
    if (!courseName || !startDate || !uploadedFile) {
      alert("Please fill all fields");
      return;
    }

    const newCourse = {
      name: courseName,
      date: startDate,
      level,
      file: uploadedFile ? uploadedFile.name : "No file uploaded",
    };

    let updatedCourses;
    if (editingIndex !== null) {
      // If editing, update the existing course
      updatedCourses = [...courses];
      updatedCourses[editingIndex] = newCourse;
      setEditingIndex(null); // Reset the editing index after updating
    } else {
      // Add new course
      updatedCourses = [...courses, newCourse];
      addCourse(newCourse); // Pass course to the parent component
    }

    // Update sessionStorage with the new courses list
    setCourses(updatedCourses);
    sessionStorage.setItem("courses", JSON.stringify(updatedCourses));

    // Clear inputs after adding/updating
    setCourseName("");
    setStartDate("");
    setLevel("Easy");
    setUploadedFile(null);
  };

  const handleEditCourse = (index) => {
    // Load the selected course details into the input fields
    const courseToEdit = courses[index];
    setCourseName(courseToEdit.name);
    setStartDate(courseToEdit.date);
    setLevel(courseToEdit.level);
    setUploadedFile({ name: courseToEdit.file }); // Set the file name for display
    setEditingIndex(index); // Set the editing index
  };

  const handleDeleteCourse = (index) => {
    // Remove the selected course from the list
    const updatedCourses = courses.filter((_, i) => i !== index);
    setCourses(updatedCourses);
    sessionStorage.setItem("courses", JSON.stringify(updatedCourses)); // Update sessionStorage
  };

  return (
    <div className="space-y-6 w-full">
      {/* Form section */}
      <div className="bg-blue-600 text-white p-6 rounded-lg space-y-4">
        <div className="flex flex-col">
          <label className="mb-2 font-semibold">Course Name</label>
          <div className="flex items-center bg-blue-800 p-2 rounded-lg">
            <input
              type="text"
              value={courseName}
              onChange={(e) => setCourseName(e.target.value)}
              placeholder="Full course on evolution of ancient animals"
              className="flex-grow bg-transparent text-white focus:outline-none placeholder-gray-300"
            />
            <FaPen className="ml-2 text-white" />
          </div>
        </div>

        <div className="flex space-x-4">
          <div className="flex-1 flex flex-col">
            <label className="mb-2 font-semibold">Starting Date</label>
            <div className="flex items-center bg-blue-800 p-2 rounded-lg">
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="flex-grow bg-transparent text-white focus:outline-none"
              />
            </div>
          </div>

          <div className="flex-1 flex flex-col">
            <label className="mb-2 font-semibold">Level</label>
            <div className="flex items-center bg-blue-800 p-2 rounded-lg">
              <select
                value={level}
                onChange={(e) => setLevel(e.target.value)}
                className="flex-grow bg-blue-800 text-white focus:outline-none p-0 rounded-lg appearance-auto"
              >
                <option value="Easy" className="bg-blue-600 text-white">
                  Easy
                </option>
                <option value="Medium" className="bg-blue-600 text-white">
                  Medium
                </option>
                <option value="Hard" className="bg-blue-600 text-white">
                  Hard
                </option>
              </select>
            </div>
          </div>

          <div className="flex-1 flex flex-col">
            <label className="mb-2 font-semibold">Add Videos/Material</label>
            <div className="flex items-center bg-blue-800 p-2 rounded-lg">
              <input
                type="file"
                onChange={handleFileChange}
                className="hidden"
                id="file-upload"
              />
              <label
                htmlFor="file-upload"
                className="flex-grow bg-transparent text-white focus:outline-none cursor-pointer"
              >
                {uploadedFile ? uploadedFile.name : "Choose File"}
              </label>
              <FaFileUpload className="ml-2 text-white" />
            </div>
          </div>
        </div>

        <button
          className="bg-green-500 text-white py-2 px-6 rounded-lg hover:bg-green-600"
          onClick={handleAddCourse}
        >
          {editingIndex !== null ? "Update Course" : "Create Course"}
        </button>
      </div>

      {/* Course list section */}
      <div className="space-y-4">
        {courses.map((course, index) => (
          <div
            key={index}
            className="bg-blue-500 text-white p-4 rounded-lg flex justify-between items-center"
          >
            <div>
              <h3 className="font-semibold">{course.name}</h3>
              <p className="text-sm">Starting Date: {course.date}</p>
              <p className="text-sm">Level: {course.level}</p>
              <p className="text-sm">Material: {course.file}</p>
            </div>
            <div className="flex space-x-4">
              <button
                className="bg-white text-blue-600 p-2 rounded-full hover:bg-gray-100"
                onClick={() => handleEditCourse(index)}
              >
                <FaEdit />
              </button>
              <button
                className="bg-white text-red-600 p-2 rounded-full hover:bg-gray-100"
                onClick={() => handleDeleteCourse(index)}
              >
                <FaTrash />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CreateEditCourses;
