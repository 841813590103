
import React from 'react';
import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';

const TeacherMainLayout = () => {
  return (
    <div className="min-h-screen flex">
      <Sidebar />
      <main className="flex-1 md:ml-64 ml-20 overflow-y-auto p-5 bg-blue-50">
        <Outlet />

      </main>
    </div>
  );
};

export default TeacherMainLayout;