import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { FaGoogle, FaApple, FaRocket } from 'react-icons/fa';
import axios from 'axios';
import NavBar from '../Home/navbar';
import waved from '../../assets/waved.svg';

const SignupForm = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    userType: 'student'
  });

  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(`${process.env.REACT_APP_URL}/user/signup`, formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (res.status === 201) {
        setResponseMessage('Signup successful!');
        console.log('OK');
      } else {
        setResponseMessage('Something went wrong. Please try again.');
        console.log('Something went wrong');
      }
    } catch (err) {
      if (err.response) {
        console.error('Error response:', err.response.data);
        setResponseMessage(err.response.data.message || 'An error occurred.');
      } else if (err.request) {
        console.error('Error request:', err.request);
        setResponseMessage('No response received from server.');
      } else {
        console.error('Error', err.message);
        setResponseMessage('An unexpected error occurred.');
      }
      console.error('Error config:', err.config);
    }
  };

  return (
    <div className="min-h-screen flex flex-col" style={{
      backgroundImage: `url(${waved})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    }}>
      <NavBar />
      <div className="flex flex-col items-center justify-center flex-1 p-6">
        <h2 className="text-3xl font-bold mb-4">Join TWKSAA <FaRocket className="inline-block" /></h2>
        <p className="text-lg mb-6">For a Brighter Future!</p>
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full max-w-md">
          {responseMessage && <div className="text-red-500 mb-4">{responseMessage}</div>}
          <div className="mb-4">
            <input
              type="text"
              name="username"
              placeholder="Enter your name"
              value={formData.username}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded mb-3 focus:outline-none focus:ring focus:ring-blue-300"
            />
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full p-3 border border-gray-300 rounded mb-3 focus:outline-none focus:ring focus:ring-blue-300"
            />
            <input
              type="password"
              name="password"
              placeholder="Enter your password"
              value={formData.password}
              onChange={handleChange}
              required
              className="w-full p-3 border border-gray-300 rounded mb-3 focus:outline-none focus:ring focus:ring-blue-300"
            />
            <select
              name="userType"
              value={formData.userType}
              onChange={handleChange}
              required
              className="w-full p-3 border border-gray-300 rounded mb-3 focus:outline-none focus:ring focus:ring-blue-300"
            >
              <option value="student">Student</option>
              <option value="teacher">Teacher</option>
              <option value="admin">Admin</option>
            </select>
          </div>
          <button type="submit" className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700 transition duration-300">
            Sign Up
          </button>
          <div className="flex justify-between items-center mt-6">
            <button type="button" className="flex items-center justify-center w-full bg-red-500 text-white py-2 rounded hover:bg-red-600 transition duration-300 mr-2">
              <FaGoogle className="mr-2" /> Sign Up with Google
            </button>
            <button type="button" className="flex items-center justify-center w-full bg-gray-900 text-white py-2 rounded hover:bg-gray-800 transition duration-300 ml-2">
              <FaApple className="mr-2" /> Sign Up with Apple
            </button>
          </div>
        </form>
      </div>
      <Outlet />
    </div>
  );
};

export default SignupForm;
