// import React, { useState } from 'react';
// import { FaSearch, FaTrash, FaChartBar, FaCalendarAlt } from 'react-icons/fa';

// // Sample student data
// const students = [
//   {
//     id: 1,
//     name: 'Arjun Kumar',
//     studentID: 'SID0123456',
//     dob: '31/01/21',
//     performance: 5,
//     attendance: 100,
//     profilePic: 'https://via.placeholder.com/150',
//   },
//   {
//     id: 2,
//     name: 'Rahul Kumar',
//     studentID: 'SID012G672',
//     dob: '21/07/13',
//     performance: 3,
//     attendance: 45,
//     profilePic: 'https://via.placeholder.com/150',
//   },
//   {
//     id: 3,
//     name: 'Munna Kumar',
//     studentID: 'SID012G672',
//     dob: '21/07/13',
//     performance: 2,
//     attendance: 32,
//     profilePic: 'https://via.placeholder.com/150',
//   },
//   {
//     id: 4,
//     name: 'Roshan Kumar',
//     studentID: 'SID012G672',
//     dob: '21/07/13',
//     performance: 5,
//     attendance: 92,
//     profilePic: 'https://via.placeholder.com/150',
//   },
//   {
//     id: 5,
//     name: 'Roshan Kumar',
//     studentID: 'SID012G672',
//     dob: '21/07/13',
//     performance: 4,
//     attendance: 70,
//     profilePic: 'https://via.placeholder.com/150',
//   }
// ];

// const StudentCard = ({ student, onDelete }) => {
//   const [showAttendance, setShowAttendance] = useState(false);
//   const [showPerformance, setShowPerformance] = useState(false);

//   return (
//     <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl m-4 transition-all duration-300 ease-in-out hover:shadow-lg">
//       <div className="md:flex">
//         <div className="md:flex-shrink-0">
//           <img
//             className="h-48 w-full object-cover md:w-48"
//             src={student.profilePic}
//             alt="Student"
//           />
//         </div>
//         <div className="p-8 w-full">
//           <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
//             Student ID: {student.studentID}
//           </div>
//           <h2 className="block mt-1 text-lg leading-tight font-medium text-black">
//             {student.name}
//           </h2>
//           <p className="mt-2 text-gray-500">Date of Birth: {student.dob}</p>

//           <div className="mt-4 flex flex-wrap gap-2">
//             <button
//               onClick={() => setShowAttendance(!showAttendance)}
//               className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out flex items-center"
//               aria-label="Check average attendance"
//             >
//               <FaCalendarAlt className="mr-2" />
//               Attendance
//             </button>
//             <button
//               onClick={() => setShowPerformance(!showPerformance)}
//               className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out flex items-center"
//               aria-label="View performance"
//             >
//               <FaChartBar className="mr-2" />
//               Performance
//             </button>
//             <button
//               onClick={onDelete}
//               className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out flex items-center"
//               aria-label="Delete student card"
//             >
//               <FaTrash className="mr-2" />
//               Delete
//             </button>
//           </div>

//           {/* Attendance Details */}
//           {showAttendance && (
//             <div
//               className="mt-4 p-4 bg-blue-100 rounded-md transition-all duration-300 ease-in-out"
//               role="region"
//               aria-label="Average Attendance"
//             >
//               <h3 className="font-bold text-blue-800">Average Attendance</h3>
//               <p>{student.attendance}%</p>
//             </div>
//           )}

//           {/* Performance Details */}
//           {showPerformance && (
//             <div
//               className="mt-4 p-4 bg-green-100 rounded-md transition-all duration-300 ease-in-out"
//               role="region"
//               aria-label="Performance Details"
//             >
//               <h3 className="font-bold text-green-800">Performance</h3>
//               <p>{student.performance}</p>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// const StudentManagement = () => {
//   const [searchQuery, setSearchQuery] = useState('');
//   const [studentList, setStudentList] = useState(students);
//   const [filterOption, setFilterOption] = useState(''); // State for filter option

//   const handleDelete = (id) => {
//     setStudentList(prevList => prevList.filter((student) => student.id !== id));
//   };

//   // Filter students based on search query and selected filter option
//   const filteredStudents = studentList
//     .filter(student =>
//       student.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
//       student.studentID.toLowerCase().includes(searchQuery.toLowerCase())
//     )
//     .filter(student => {
//       if (filterOption === 'performance') {
//         return student.performance >= 4.0; // Example filter condition for performance
//       } else if (filterOption === 'attendance') {
//         return student.attendance >= 90; // Example filter condition for attendance
//       }
//       return true; // If no filter is applied, show all students
//     });

//   // Function to set filter option and reset other filters
//   const applyFilter = (option) => {
//     setFilterOption(option);
//   };

//   return (
//     <div className="p-4">
//       {/* Search bar */}
//       <div className="flex justify-between items-center mb-6">
//         <div className="relative w-2/3">
//           <input
//             type="text"
//             placeholder="Name, course name, or ID etc."
//             value={searchQuery}
//             onChange={(e) => setSearchQuery(e.target.value)}
//             className="w-full p-2 pl-10 border border-gray-300 rounded-full focus:outline-none"
//           />
//           <FaSearch className="absolute left-3 top-3 text-gray-400" />
//         </div>
//       </div>

//       {/* Filter buttons */}
//       <div className="flex space-x-4 mb-6">
//         <button onClick={() => applyFilter('attendance')} className="px-4 py-2 bg-blue-200 rounded-full text-blue-700">Above Avg Attend</button>
//         <button onClick={() => applyFilter('performance')} className="px-4 py-2 bg-green-200 rounded-full text-green-700">Above Avg Performance</button>
//       </div>

//       {/* Student Cards */}
//       <div className="grid gap-4">
//         {filteredStudents.map((student) => (
//           <StudentCard
//             key={student.id}
//             student={student}
//             onDelete={() => handleDelete(student.id)}
//           />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default StudentManagement;

// import React, { useState } from 'react';
// import { FaSearch, FaTrash, FaChartBar, FaCalendarAlt } from 'react-icons/fa';

// // Sample student data
// const students = [
//   {
//     id: 1,
//     name: 'Arjun Kumar',
//     studentID: 'SID0123456',
//     dob: '31/01/21',
//     performance: 5,
//     attendance: 100,
//     profilePic: 'https://via.placeholder.com/150',
//   },
//   {
//     id: 2,
//     name: 'Rahul Kumar',
//     studentID: 'SID012G672',
//     dob: '21/07/13',
//     performance: 3,
//     attendance: 45,
//     profilePic: 'https://via.placeholder.com/150',
//   },
//   {
//     id: 3,
//     name: 'Munna Kumar',
//     studentID: 'SID012G672',
//     dob: '21/07/13',
//     performance: 2,
//     attendance: 32,
//     profilePic: 'https://via.placeholder.com/150',
//   },
//   {
//     id: 4,
//     name: 'Roshan Kumar',
//     studentID: 'SID012G672',
//     dob: '21/07/13',
//     performance: 5,
//     attendance: 92,
//     profilePic: 'https://via.placeholder.com/150',
//   },
//   {
//     id: 5,
//     name: 'Roshan Kumar',
//     studentID: 'SID012G672',
//     dob: '21/07/13',
//     performance: 4,
//     attendance: 70,
//     profilePic: 'https://via.placeholder.com/150',
//   }
// ];

// const StudentCard = ({ student, onDelete }) => {
//   const [showAttendance, setShowAttendance] = useState(false);
//   const [showPerformance, setShowPerformance] = useState(false);

//   return (
//     <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl m-4 transition-all duration-300 ease-in-out hover:shadow-lg">
//       <div className="md:flex">
//         <div className="md:flex-shrink-0">
//           <img
//             className="h-48 w-full object-cover md:w-48"
//             src={student.profilePic}
//             alt="Student"
//           />
//         </div>
//         <div className="p-8 w-full">
//           <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
//             Student ID: {student.studentID}
//           </div>
//           <h2 className="block mt-1 text-lg leading-tight font-medium text-black">
//             {student.name}
//           </h2>
//           <p className="mt-2 text-gray-500">Date of Birth: {student.dob}</p>

//           <div className="mt-4 flex flex-wrap gap-2">
//             <button
//               onClick={() => setShowAttendance(!showAttendance)}
//               className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out flex items-center"
//               aria-label="Check average attendance"
//             >
//               <FaCalendarAlt className="mr-2" />
//               Attendance
//             </button>
//             <button
//               onClick={() => setShowPerformance(!showPerformance)}
//               className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out flex items-center"
//               aria-label="View performance"
//             >
//               <FaChartBar className="mr-2" />
//               Performance
//             </button>
//             <button
//               onClick={onDelete}
//               className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out flex items-center"
//               aria-label="Delete student card"
//             >
//               <FaTrash className="mr-2" />
//               Delete
//             </button>
//           </div>

//           {/* Attendance Details */}
//           {showAttendance && (
//             <div
//               className="mt-4 p-4 bg-blue-100 rounded-md transition-all duration-300 ease-in-out"
//               role="region"
//               aria-label="Average Attendance"
//             >
//               <h3 className="font-bold text-blue-800">Average Attendance</h3>
//               <p>{student.attendance}%</p>
//             </div>
//           )}

//           {/* Performance Details */}
//           {showPerformance && (
//             <div
//               className="mt-4 p-4 bg-green-100 rounded-md transition-all duration-300 ease-in-out"
//               role="region"
//               aria-label="Performance Details"
//             >
//               <h3 className="font-bold text-green-800">Performance</h3>
//               <p>{student.performance}</p>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// const StudentManagement = () => {
//   const [searchQuery, setSearchQuery] = useState('');
//   const [studentList, setStudentList] = useState(students);
//   const [filterOption, setFilterOption] = useState(''); // State for filter option

//   const handleDelete = (id) => {
//     setStudentList(prevList => prevList.filter((student) => student.id !== id));
//   };

//   // Filter students based on search query and selected filter option
//   const filteredStudents = studentList
//     .filter(student =>
//       student.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
//       student.studentID.toLowerCase().includes(searchQuery.toLowerCase())
//     )
//     .filter(student => {
//       if (filterOption === 'performance') {
//         return student.performance >= 4.0; // Example filter condition for performance
//       } else if (filterOption === 'attendance') {
//         return student.attendance >= 90; // Example filter condition for attendance
//       }
//       return true; // If no filter is applied, show all students
//     });

//   // Function to set filter option and reset other filters
//   const applyFilter = (option) => {
//     setFilterOption(option);
//   };

//   return (
//     <div className="p-4">
//       {/* Search bar */}
//       <div className="flex justify-between items-center mb-6">
//         <div className="relative w-2/3">
//           <input
//             type="text"
//             placeholder="Name, course name, or ID etc."
//             value={searchQuery}
//             onChange={(e) => setSearchQuery(e.target.value)}
//             className="w-full p-2 pl-10 border border-gray-300 rounded-full focus:outline-none"
//           />
//           <FaSearch className="absolute left-3 top-3 text-gray-400" />
//         </div>
//       </div>

//       {/* Filter buttons */}
//       <div className="flex space-x-4 mb-6">
//         <button onClick={() => applyFilter('attendance')} className="px-4 py-2 bg-blue-200 rounded-full text-blue-700">Above Avg Attend</button>
//         <button onClick={() => applyFilter('performance')} className="px-4 py-2 bg-green-200 rounded-full text-green-700">Above Avg Performance</button>
//       </div>

//       {/* Student Cards */}
//       <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//         {filteredStudents.map((student) => (
//           <StudentCard
//             key={student.id}
//             student={student}
//             onDelete={() => handleDelete(student.id)}
//           />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default StudentManagement;

import React, { useState } from "react";
import { FaSearch, FaTrash, FaChartBar, FaCalendarAlt } from "react-icons/fa";

// Sample student data
const students = [
  {
    id: 1,
    name: "Arjun Kumar",
    studentID: "SID0123456",
    dob: "31/01/21",
    performance: 5,
    attendance: 100,
    profilePic: "https://via.placeholder.com/150",
  },
  {
    id: 2,
    name: "Rahul Kumar",
    studentID: "SID012G672",
    dob: "21/07/13",
    performance: 3,
    attendance: 45,
    profilePic: "https://via.placeholder.com/150",
  },
  {
    id: 3,
    name: "Munna Kumar",
    studentID: "SID012G672",
    dob: "21/07/13",
    performance: 2,
    attendance: 32,
    profilePic: "https://via.placeholder.com/150",
  },
  {
    id: 4,
    name: "Roshan Kumar",
    studentID: "SID012G672",
    dob: "21/07/13",
    performance: 5,
    attendance: 92,
    profilePic: "https://via.placeholder.com/150",
  },
  {
    id: 5,
    name: "Roshan Kumar",
    studentID: "SID012G672",
    dob: "21/07/13",
    performance: 4,
    attendance: 70,
    profilePic: "https://via.placeholder.com/150",
  },
];

const StudentCard = ({ student, onDelete }) => {
  const [showAttendance, setShowAttendance] = useState(false);
  const [showPerformance, setShowPerformance] = useState(false);

  return (
    <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl m-4 transition-all duration-300 ease-in-out hover:shadow-lg">
      <div className="md:flex">
        <div className="md:flex-shrink-0">
          <img
            className="h-48 w-full object-cover md:m-8 md:w-48"
            src={student.profilePic}
            alt="Student"
          />
        </div>
        <div className="p-8 w-full">
          <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
            Student ID: {student.studentID}
          </div>
          <h2 className="block mt-1 text-lg leading-tight font-medium text-black">
            {student.name}
          </h2>
          <p className="mt-2 text-gray-500">Date of Birth: {student.dob}</p>

          <div className="mt-4 flex flex-wrap gap-2">
            <button
              onClick={() => setShowAttendance(!showAttendance)}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out flex items-center"
              aria-label="Check average attendance"
            >
              <FaCalendarAlt className="mr-2" />
              Attendance
            </button>
            <button
              onClick={() => setShowPerformance(!showPerformance)}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out flex items-center"
              aria-label="View performance"
            >
              <FaChartBar className="mr-2" />
              Performance
            </button>
            <button
              onClick={onDelete}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out flex items-center"
              aria-label="Delete student card"
            >
              <FaTrash className="mr-2" />
              Delete
            </button>
          </div>

          {/* Attendance Details */}
          {showAttendance && (
            <div
              className="mt-4 p-4 bg-blue-100 rounded-md transition-all duration-300 ease-in-out"
              role="region"
              aria-label="Average Attendance"
            >
              <h3 className="font-bold text-blue-800">Average Attendance</h3>
              <p>{student.attendance}%</p>
            </div>
          )}

          {/* Performance Details */}
          {showPerformance && (
            <div
              className="mt-4 p-4 bg-green-100 rounded-md transition-all duration-300 ease-in-out"
              role="region"
              aria-label="Performance Details"
            >
              <h3 className="font-bold text-green-800">Performance</h3>
              <p>{student.performance}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const StudentManagement = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [studentList, setStudentList] = useState(students);
  const [filterOption, setFilterOption] = useState(""); // State for filter option

  const handleDelete = (id) => {
    setStudentList((prevList) =>
      prevList.filter((student) => student.id !== id)
    );
  };

  // Filter students based on search query and selected filter option
  const filteredStudents = studentList
    .filter(
      (student) =>
        student.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.studentID.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .filter((student) => {
      if (filterOption === "performance") {
        return student.performance >= 4.0; // Example filter condition for performance
      } else if (filterOption === "attendance") {
        return student.attendance >= 90; // Example filter condition for attendance
      }
      return true; // If no filter is applied, show all students
    });

  // Function to set filter option and reset other filters
  const applyFilter = (option) => {
    setFilterOption(option);
  };

  return (
    <div className="p-4">
      {/* Centered Search Bar */}
      <div className="flex flex-col items-center mb-6">
        <div className="relative w-full md:w-2/3 mb-4">
          <input
            type="text"
            placeholder="Name, course name, or ID etc."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full p-2 pl-10 border border-gray-300 rounded-full focus:outline-none"
          />
          <FaSearch className="absolute left-3 top-3 text-gray-400" />
        </div>

        {/* Filter buttons */}
        <div className="flex flex-wrap justify-center gap-3 space-x-4 mb-6">
          <button
            onClick={() => applyFilter("attendance")}
            className="px-4 py-2 bg-blue-200 rounded-full text-blue-700"
          >
            Above Avg Attend
          </button>
          <button
            onClick={() => applyFilter("performance")}
            className="px-4 py-2 bg-green-200 rounded-full text-green-700"
          >
            Above Avg Performance
          </button>
        </div>
      </div>

      {/* Student Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {filteredStudents.map((student) => (
          <StudentCard
            key={student.id}
            student={student}
            onDelete={() => handleDelete(student.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default StudentManagement;
