import React, { useState } from "react";
import Assignments from "./Assignments";
import Exams from "./Exams";

const AssignmentsExams = () => {
  const [activeTab, setActiveTab] = useState("Assignments");

  return (
    <div className="p-6 bg-blue-50 min-h-screen">
    <div className="flex space-x-6 mb-6 border-b">
        <button className={`px-4 py-2 font-semibold ${activeTab === 'Assignments' ? 'border-b-4 border-blue-500 text-black bg-blue-200' : 'text-gray-600'}`} onClick={() => setActiveTab('Assignments')}>
                Assignments
        </button>
        <button className={`px-4 py-2 font-semibold ${activeTab === 'Exams' ? 'border-b-4 border-blue-500 text-black bg-blue-200' : 'text-gray-600'}`}
          onClick={() => setActiveTab('Exams')}>
                Exams
        </button>
  
    </div>


          <div>
              {activeTab === 'Assignments' && <Assignments />}
              {activeTab === 'Exams' && <Exams />}
          </div>
    </div>

  );
};
export default AssignmentsExams;