import React from 'react';
import {  NavLink } from 'react-router-dom';
import { FaHome, FaHandsHelping, FaSignInAlt, FaUserPlus } from 'react-icons/fa';

const NavBar = () => {
  return (
    <nav className="bg-gradient-to-r from-blue-500 to-blue-700 p-4 shadow-lg">
      <ul className="flex flex-col md:flex-row justify-center md:space-x-8 text-white font-semibold">
        <li>
          <NavLink
            to="/"
            className={({ isActive }) => 
              `flex items-center hover:text-gray-300 transition duration-300 ease-in-out ${isActive ? 'text-gray-300' : ''}`
            }
          >
            <FaHome className="mr-1" /> Home
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/DonationForm"
            className={({ isActive }) => 
              `flex items-center hover:text-gray-300 transition duration-300 ease-in-out ${isActive ? 'text-gray-300' : ''}`
            }
          >
            <FaHandsHelping className="mr-1" /> Donation
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/LoginForm"
            className={({ isActive }) => 
              `flex items-center hover:text-gray-300 transition duration-300 ease-in-out ${isActive ? 'text-gray-300' : ''}`
            }
          >
            <FaSignInAlt className="mr-1" /> Login
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/SignupForm"
            className={({ isActive }) => 
              `flex items-center hover:text-gray-300 transition duration-300 ease-in-out ${isActive ? 'text-gray-300' : ''}`
            }
          >
            <FaUserPlus className="mr-1" /> Join
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
