import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';

const liveclassNamees = [
    { id: 1, subject: 'React Routing', time: '10:00 AM' },
    { id: 2, subject: 'Project', time: '1:00 PM' },
];

const upcomingclassNamees = [
    { id: 1, subject: 'Redux', date: '2024-08-14', time: '11:00 AM' },
    { id: 2, subject: 'React States', date: '2024-08-15', time: '2:00 PM' },
];

const completedclassNamees = [
    { id: 1, subject: 'React Hooks', date: '2024-08-12', time: 'completed' },
    { id: 2, subject: 'Optimization', date: '2024-08-11', time: 'completed' },
];

const StudentClassSchedule = () => {
    const [activeSection, setActiveSection] = useState('live');
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const renderclassNamees = () => {
        let classNamees;
        switch (activeSection) {
            case 'live':
                classNamees = liveclassNamees;
                break;
            case 'upcoming':
                classNamees = upcomingclassNamees;
                break;
            case 'completed':
                classNamees = completedclassNamees;
                break;
            default:
                classNamees = [];
        }

        return (
            <ul className="space-y-2">
                {classNamees.map((cls) => (
                    <li key={cls.id} className="p-4 bg-gray-100 rounded shadow-md flex justify-between items-center">
                        <div className='flex flex-col'>
                            <h3 className="text-lg font-semibold">{cls.subject}</h3>
                            {activeSection === 'completed' ? (
                                <p className="text-green-600 text-sm">• Completed</p>
                            ) : (
                                <p>{`Time: ${cls.time}${cls.date ? ` on ${cls.date}` : ''}`}</p>
                            )}
                        </div>
                        {activeSection === 'live' && (
                            <button
                                onClick={() => handleJoin(cls.time)}
                                className="ml-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                            >
                                Join
                            </button>
                        )}
                    </li>
                ))}
            </ul>
        );
    };

    const handleJoin = (classNameTime) => {
        const currentTime = new Date();
        const [classNameHour, classNameMinute] = classNameTime.split(' ')[0].split(':').map(Number);
        const classNamePeriod = classNameTime.split(' ')[1];
        let classNameTimeInMinutes = classNameHour * 60 + classNameMinute;
        if (classNamePeriod === 'PM' && classNameHour !== 12) classNameTimeInMinutes += 720;
        if (classNamePeriod === 'AM' && classNameHour === 12) classNameTimeInMinutes -= 720;

        const currentHour = currentTime.getHours();
        const currentMinute = currentTime.getMinutes();
        let currentTimeInMinutes = currentHour * 60 + currentMinute;

        if (currentHour >= 12) currentTimeInMinutes += 720; 

        if (currentTimeInMinutes === classNameTimeInMinutes) {
            alert(`Joining the live className at ${classNameTime}`);
        } else {
            alert(`You can only join the className at ${classNameTime}`);
        }
    };

    return (
        <div className="relative flex flex-col md:flex-row">

            <button
                className="md:hidden fixed top-4 left-4 z-40 p-2 bg-gray-600 text-white rounded"
                onClick={() => setSidebarOpen(!sidebarOpen)}
            >
                <FaBars className="w-6 h-6" />
            </button>

            <div
                className={`flex-1 p-4 md:p-6 bg-gray-50 transition-transform duration-300 ease-in-out ${sidebarOpen ? 'ml-64' : 'md:ml-0'} overflow-y-auto`}
                style={{ zIndex: 20 }}
            >
                <div className="relative">
                    <h2 className="text-2xl md:text-3xl font-semibold mb-4 py-5 pl-6 md:pl-72">className Schedule</h2>
                </div>
                <div className="mb-4 flex flex-wrap gap-2">
                    <button
                        onClick={() => setActiveSection('live')}
                        className={`p-2 border rounded-lg ${activeSection === 'live' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
                    >
                        Live classNamees
                    </button>
                    <button
                        onClick={() => setActiveSection('upcoming')}
                        className={`p-2 border rounded-lg ${activeSection === 'upcoming' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
                    >
                        Upcoming classNamees
                    </button>
                    <button
                        onClick={() => setActiveSection('completed')}
                        className={`p-2 border rounded-lg ${activeSection === 'completed' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
                    >
                        Completed classNamees
                    </button>
                </div>
                {renderclassNamees()}
            </div>
        </div>
    );
};

export default StudentClassSchedule;
