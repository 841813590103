


import React, { useState } from "react";
import { FaCalendarAlt, FaClock, FaPen, FaTrash, FaPlus,FaSave } from "react-icons/fa";

const Exams = () => {
  const [examTitle, setExamTitle] = useState("Get familiar with the basics of Rust programming language");
  const [questions, setQuestions] = useState([]);
  const [savedQuestions, setSavedQuestions] = useState([]);

  const addOption = (qIndex) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options.push("");
    setQuestions(newQuestions);
  };

  const handleOptionChange = (qIndex, oIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options[oIndex] = value;
    setQuestions(newQuestions);
  };

  const handleQuestionChange = (qIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].question = value;
    setQuestions(newQuestions);
  };

  const addQuestion = () => {
    setQuestions([
      ...questions,
      { id: Date.now(), question: "", options: [] },
    ]);
  };

  const handleDeleteOption = (qIndex, oIndex) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options.splice(oIndex, 1);
    setQuestions(newQuestions);
  };

  const handleDeleteQuestion = (qIndex) => {
    const newQuestions = questions.filter((_, index) => index !== qIndex);
    setQuestions(newQuestions);
  };

  const handleSaveQuestion = (qIndex) => {
    const questionToSave = questions[qIndex];
    setSavedQuestions([...savedQuestions, { ...questionToSave }]);
    handleDeleteQuestion(qIndex); // Remove from the current list once saved

    // Clear inputs after saving
    setQuestions((prevQuestions) => [
      ...prevQuestions.slice(0, qIndex),
      { id: Date.now(), question: "", options: [] },
      ...prevQuestions.slice(qIndex + 1),
    ]);
  };

  const handleDeleteSavedQuestion = (index) => {
    const newSavedQuestions = savedQuestions.filter((_, i) => i !== index);
    setSavedQuestions(newSavedQuestions);
  };

  return (
    <div className="p-4 space-y-6 bg-blue-500 rounded-md text-white sm:p-6">
      {/* Exam Title and Description */}
      <div className="space-y-2">
        <label className="block font-semibold">Title and Description:</label>
        <div className="flex items-center space-x-2 bg-white p-3 rounded-md text-black">
          <input
            type="text"
            placeholder="Exam Title"
            className="w-full outline-none bg-transparent"
            value={examTitle}
            onChange={(e) => setExamTitle(e.target.value)}
          />
          <FaPen className="text-blue-500" />
        </div>
      </div>

      {/* Examination Details */}
      <div className="flex flex-wrap gap-2">
        <button className="flex-1 bg-blue-700 text-white px-4 py-2 rounded flex items-center justify-center sm:w-auto">
          <FaCalendarAlt />
          <span className="ml-2">Examination Date</span>
        </button>
        <button className="flex-1 bg-blue-700 text-white px-4 py-2 rounded flex items-center justify-center sm:w-auto">
          <FaClock />
          <span className="ml-2">Examination Time</span>
        </button>
        <button className="flex-1 bg-blue-700 text-white px-4 py-2 rounded flex items-center justify-center sm:w-auto">
          <FaClock />
          <span className="ml-2">Exam Duration</span>
        </button>
      </div>

      {/* Add Questions Section */}
      <div className="space-y-6">
        {questions.map((question, qIndex) => (
          <div key={question.id} className="bg-white p-4 rounded-md text-black space-y-4">
            <div className="flex items-center space-x-2">
              <input
                type="text"
                className="w-full outline-none bg-gray-100 p-2 rounded-md"
                placeholder="Question"
                value={question.question}
                onChange={(e) => handleQuestionChange(qIndex, e.target.value)}
              />
              <FaPen className="text-blue-500" />
            </div>

            <div className="space-y-2">
              {question.options.map((option, oIndex) => (
                <div key={oIndex} className="flex items-center space-x-2">
                  <span className="inline-flex items-center justify-center w-6 h-6 text-white bg-blue-600 rounded-full">
                    {oIndex + 1}
                  </span>
                  <input
                    type="text"
                    className="w-full outline-none bg-gray-100 p-2 rounded-md"
                    value={option}
                    onChange={(e) => handleOptionChange(qIndex, oIndex, e.target.value)}
                  />
                  <button
                    className="bg-red-500 text-white px-2 py-2 rounded"
                    onClick={() => handleDeleteOption(qIndex, oIndex)}
                  >
                    <FaTrash />
                  </button>
                </div>
              ))}
              <button
                className="bg-green-500 text-white px-4 py-2 rounded flex items-center space-x-2"
                onClick={() => addOption(qIndex)}
              >
                <FaPlus />
                <span>Add Option</span>
              </button>
            </div>

            <div className="flex justify-end space-x-2">
              <button
                className="bg-green-600 text-white px-4 py-2 rounded flex items-center space-x-2"
                onClick={() => handleSaveQuestion(qIndex)}
              >
                <FaSave />
                <span>Save</span>
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded flex items-center space-x-2"
                onClick={() => handleDeleteQuestion(qIndex)}
              >
                <FaTrash />
                <span>Delete</span>
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Add Question Button */}
      <div className="flex justify-end">
        <button
          className="bg-blue-700 text-white px-6 py-2 rounded flex items-center space-x-2"
          onClick={addQuestion}
        >
          <FaPlus />
          <span>Add Question</span>
        </button>
      </div>

      {/* Display Saved Questions */}
      <div className="text-black mt-8">
        <h2 className="font-semibold text-lg">Saved Questions</h2>
        {savedQuestions.length > 0 ? (
          savedQuestions.map((savedQuestion, index) => (
            <div key={index} className="my-2 p-4 bg-white rounded-md shadow-md transition hover:shadow-lg">
              <p className="font-semibold text-lg">
                Question {index + 1}: {savedQuestion.question}
              </p>
              <ul className="ml-5 mt-1">
                {savedQuestion.options.map((option, optIndex) => (
                  <li key={optIndex} className="flex items-center space-x-2 mb-2">
                    <span className="inline-flex items-center justify-center w-6 h-6 text-white bg-blue-600 rounded-full">
                      {optIndex + 1}
                    </span>
                    <span className="text-gray-700">{option}</span>
                  </li>
                ))}
              </ul>
              <div className="flex justify-end space-x-2 mt-2">
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded flex items-center space-x-2"
                  onClick={() => handleDeleteSavedQuestion(index)}
                >
                  <FaTrash />
                  <span>Delete</span>
                </button>
              </div>
            </div>
          ))
        ) : (
          <p className="text-white">No questions have been saved yet.</p>
        )}
      </div>

      {/* Continue Button */}
      <div className="flex justify-center">
        <button className="bg-green-500 text-white py-2 mt-10 rounded-lg px-6 w-full sm:w-auto sm:px-28 hover:bg-green-600">
          Continue
        </button>
      </div>
    </div>
  );
};

export default Exams;
