import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa'; 

const StudentSupport = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    setTimeout(() => {
      setIsSubmitting(false);
      setFormData({ name: '', email: '', message: '' });
      setSubmissionStatus('Your message has been sent successfully!');
    }, 2000);
  };

  return (
    <div className="flex flex-col md:flex-row">
      <div
        className={`flex-1 p-6 bg-gray-50 h-screen overflow-y-auto transition-all ${
          isSidebarOpen ? 'ml-64' : 'md:ml-4'
        }`}
      >
        <button
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="md:hidden bg-gray-500 text-lg p-2 rounded text-white shadow-md absolute top-4 left-4 z-10"
        >
          <FaBars />
        </button>

        <div className="pt-16 md:pt-0">
          <form onSubmit={handleSubmit} className="w-full max-w-2xl mx-auto p-4 bg-white shadow-md rounded-lg">
            <h1 className="font-bold text-2xl mb-4">Hi!, How can we help?</h1>
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-700">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded w-full"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded w-full"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="message" className="block text-gray-700">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded w-full h-32"
                required
              ></textarea>
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className={`w-full py-2 px-4 rounded text-white ${isSubmitting ? 'bg-gray-500' : 'bg-blue-500 hover:bg-blue-700'}`}
            >
              {isSubmitting ? 'Sending...' : 'Send Message'}
            </button>
            {submissionStatus && (
              <p className="mt-4 text-green-600">{submissionStatus}</p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default StudentSupport;
