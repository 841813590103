import React, { useState } from 'react';
import axios from 'axios';
import NavBar from '../Home/navbar';
import waved from '../../assets/waved.svg';
const DonationForm = () => {
    const [email, setEmail] = useState('');
    const [amount, setAmount] = useState('');
    const [errors, setErrors] = useState({});

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => resolve(true);
            script.onerror = () => resolve(false);
            document.body.appendChild(script);
        });
    };

    const validateForm = () => {
        const newErrors = {};
        let isValid = true;

        if (!email) {
            newErrors.email = 'Email is required!';
            isValid = false;
        }
        if (!amount || isNaN(amount) || amount <= 0) {
            newErrors.amount = 'Valid amount is required!';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const createRazorPayPayment = async () => {
        if (!validateForm()) {
            return;
        }

        let data = JSON.stringify({
            amount: amount * 100,
            currency: 'INR',
        });

        let config = {
            method: 'post',
            url: `http://${process.env.REACT_APP_URL}/payments`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: data,
        };

        try {
            const response = await axios.request(config);
            handleRazorPayScreen(response.data);
        } catch (err) {
            console.error('Error in payment creation:', err);
        }
    };

    const handleRazorPayScreen = async (paymentData) => {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

        if (!res) {
            alert('Error loading Razorpay script');
            return;
        }

        const options = {
            key: 'rzp_test_MvsM37uWJWGR7B',
            amount: paymentData.amount,
            currency: 'INR',
            name: 'TWKSAA FOUNDATION',
            description: 'Donation Payment',
            handler: function (response) {
                console.log('Payment Success:', response);
            },
            prefill: {
                name: userName,
                email: email,
                contact: contactNo,
            },
            theme: {
                color: '#0000ff',
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };

    return (
        <div className="min-h-screen flex flex-col" style={{
            backgroundImage: `url(${waved})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}>
            <NavBar />
            <div className="flex justify-center items-center flex-grow">
                <div className="bg-white shadow-lg rounded-lg p-8 max-w-lg w-full">
                    <div className="text-center mb-6">
                        <h1 className="text-3xl font-bold text-gray-900">Support!</h1>
                        <h3 className="text-xl text-blue-600">TWKSAA WELFARE FOUNDATION</h3>
                        <p className="text-sm text-gray-500 mt-2">
                            Your contribution will empower students and educators, fostering innovation and academic excellence.
                        </p>
                    </div>

                    <form className="space-y-4">
                        <h3 className="text-lg font-semibold text-gray-700">User Details:</h3>

                        <div className="space-y-1">
                            <label htmlFor="email" className="text-gray-600">Email</label>
                            <input
                                id="email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full p-2 border rounded-md"
                            />
                            {errors.email && <span className="text-red-500 text-sm">{errors.email}</span>}
                        </div>

                        <div className="space-y-1">
                            <label htmlFor="amount" className="text-gray-600">Donation Amount (INR)</label>
                            <input
                                id="amount"
                                type="number"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                className="w-full p-2 border rounded-md"
                            />
                            {errors.amount && <span className="text-red-500 text-sm">{errors.amount}</span>}
                        </div>

                        <button
                            type="button"
                            onClick={createRazorPayPayment}
                            className="bg-blue-600 text-white py-2 px-4 rounded-md w-full hover:bg-blue-700 transition"
                        >
                            Proceed to Payment
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default DonationForm;
