import React, { useState } from 'react';
import CurrentCourses from './CurrentCourses';
import CreateEditCourses from './CreateEditCourses';
import CourseMaterials from './CourseMaterials';

const CourseManagement = () => {
  const [activeTab, setActiveTab] = useState('current');
  const [courses, setCourses] = useState([]);

  // Function to add new course
  const addCourse = (newCourse) => {
    setCourses((prevCourses) => [...prevCourses, newCourse]);
  };

  return (
    <div className="w-full p-6">
      {/* Tab buttons */}
      <div className="flex space-x-6 mb-6 border-b">
        <button
          className={`px-4 py-2 font-semibold ${activeTab === 'current' ? 'border-b-4 border-blue-500 text-black bg-blue-200' : 'text-gray-600'}`}
          onClick={() => setActiveTab('current')}
        >
          Current Courses
        </button>
        <button
          className={`px-4 py-2 font-semibold ${activeTab === 'create' ? 'border-b-4 border-blue-500 text-black bg-blue-200' : 'text-gray-600'}`}
          onClick={() => setActiveTab('create')}
        >
          Create/Edit Courses
        </button>
        <button
          className={`px-4 py-2 font-semibold ${activeTab === 'materials' ? 'border-b-4 border-blue-500 text-black bg-blue-200' : 'text-gray-600'}`}
          onClick={() => setActiveTab('materials')}
        >
          Course Materials
        </button>
      </div>

      {/* Tab content */}
      <div>
        {activeTab === 'current' && <CurrentCourses />}
        {activeTab === 'create' && <CreateEditCourses addCourse={addCourse} />}
        {activeTab === 'materials' && <CourseMaterials courses={courses} />}
      </div>
    </div>
  );
};

export default CourseManagement;
