import React from "react";

const CurrentCourses = () => {
  const courses = [
    {
      id: 1,
      title: "Full course on evolution of ancient animals",
      schedule: "10:30 AM, Jul 25",
      assignments: 10,
      enrolled: 37,
      image: "https://via.placeholder.com/150", // Placeholder for the course image
    },
    {
      id: 2,
      title: "Full course on evolution of ancient animals",
      schedule: "10:30 AM, Jul 25",
      assignments: 10,
      enrolled: 37,
      image:
        "https://images.unsplash.com/photo-1728025487156-3581ff11e237?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHwyMnx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 3,
      title: "Full course on evolution of ancient animals",
      schedule: "10:30 AM, Jul 25",
      assignments: 10,
      enrolled: 37,
      image: "https://via.placeholder.com/150",
    },
  ];

  return (
    <div className="space-y-6">
      {courses.map((course) => (
        <div
          key={course.id}
          className="border-2 border-blue-500 rounded-lg p-6 shadow-md flex items-center justify-between w-full lg:w-4/5 mx-auto"
        // Increased the padding to p-6 and made width adjustments
        >
          {/* Image Section */}
          <div className="flex items-center">
            <img
              src={course.image}
              alt="Course"
              className="w-24 h-24 rounded-md"
            />
          </div>

          {/* Course Info Section */}
          <div className="flex-grow px-4">
            {/* First Div: Course Name and Schedule */}
            <div className="flex justify-between items-center mb-2">
              <div>
                <h3 className="font-semibold text-blue-700 text-lg">
                  {course.title}
                </h3>
                <p className="text-sm text-blue-500">
                  Next Class Schedule: {course.schedule}
                </p>
                <hr className="border-t-2 border-blue-500 mt-2" />
              </div>

              <button className="p-2 bg-blue-100 rounded-full hover:bg-blue-200 ml-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M5 19h1.425L16.2 9.225L14.775 7.8L5 17.575zm-2 2v-4.25L17.625 2.175L21.8 6.45L7.25 21zM19 6.4L17.6 5zm-3.525 2.125l-.7-.725L16.2 9.225z"
                  />
                </svg>
                {/* Edit icon */}
              </button>
            </div>

            {/* Added gap between the two divs */}
            <div className="mt-6 flex justify-between items-center">
              <div className="text-sm text-blue-600">
                <p>Total assignments: {course.assignments}</p>
                <p>Enrolled: {course.enrolled} Students</p>
              </div>
              <button className="p-2 bg-blue-100 rounded-full hover:bg-blue-200 ml-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M17 22q-1.25 0-2.125-.875T14 19q0-.15.075-.7L7.05 14.2q-.4.375-.925.588T5 15q-1.25 0-2.125-.875T2 12t.875-2.125T5 9q.6 0 1.125.213t.925.587l7.025-4.1q-.05-.175-.062-.337T14 5q0-1.25.875-2.125T17 2t2.125.875T20 5t-.875 2.125T17 8q-.6 0-1.125-.213T14.95 7.2l-7.025 4.1q.05.175.063.338T8 12t-.012.363t-.063.337l7.025 4.1q.4-.375.925-.587T17 16q1.25 0 2.125.875T20 19t-.875 2.125T17 22m0-2q.425 0 .713-.287T18 19t-.288-.712T17 18t-.712.288T16 19t.288.713T17 20M5 13q.425 0 .713-.288T6 12t-.288-.712T5 11t-.712.288T4 12t.288.713T5 13m12-7q.425 0 .713-.288T18 5t-.288-.712T17 4t-.712.288T16 5t.288.713T17 6m0-1"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CurrentCourses;
