import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faChalkboardTeacher, faUsers, faFileAlt, faSignOutAlt, faHouse } from '@fortawesome/free-solid-svg-icons';

const Sidebar = () => {
  const navigate = useNavigate();

  const handleLogoutBtn = () => {
    navigate('/');
  };

  // Reusable Navigation Link Component
  const NavItem = ({ to, icon, label }) => (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `flex items-center space-x-2 py-2 px-3 border border-white rounded-lg w-full justify-center md:justify-start ${isActive ? 'bg-blue-700' : 'hover:bg-blue-400'
        }`
      }
    >
      <FontAwesomeIcon icon={icon} className="text-2xl" />
      <span className="hidden md:inline md:text-sm">{label}</span>
    </NavLink>
  );

  return (
    <aside className="bg-blue-900 text-white fixed top-0 left-0 h-full md:w-64 w-20 p-4 flex flex-col transition-width duration-300">
      {/* Profile Section */}
      <div className="flex flex-col items-center mb-6">
        <img
          src="https://via.placeholder.com/50" // Replace with your profile picture URL
          alt="Profile"
          className="w-12 h-12 rounded-full border-2 border-white mb-2"
        />
        <div className="text-center">
          <h2 className="text-lg font-semibold">Dr. Rahima</h2>
          <p className="text-sm">Professor</p>
        </div>
      </div>

      {/* Navigation Links */}
      <nav className="flex-grow flex flex-col items-center mt-8 space-y-4">
        
        <NavItem to="TeacherDashboard" icon={faHouse} label="Dashboard" />
        <NavItem to="TeacherProfile" icon={faUser} label="Profile" />
        <NavItem to="TeacherStudents" icon={faUsers} label="Student Management" />
        <NavItem to="TeacherCourses" icon={faChalkboardTeacher} label="Course Management" />
        <NavItem to="TeacherAssignmentExams" icon={faFileAlt} label="Assignments & Exams" />
      </nav>

      {/* Logout Button */}
      <button
        className="mt-auto flex items-center space-x-2 bg-red-500 py-2 px-3 rounded hover:bg-red-600 w-full justify-center md:justify-start md:py-1 md:px-2"
        onClick={handleLogoutBtn}
      >
        <FontAwesomeIcon icon={faSignOutAlt} className="text-2xl" />
        <span className="hidden md:inline md:text-sm">Logout</span>
      </button>
    </aside>
  );
};

export default Sidebar;
