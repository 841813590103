import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginForm from './Components/Login/LoginForm';
import Home from './Components/Home/Home';
import SignupForm from './Components/Login/SignupForm';
import DonationForm from './Components/Donation/DonationForm';
import ForgotPassword from './Components/Login/forgot/ForgotPassword';
import { CourseProvider } from './Components/StudentPortal/components/Context/CreateContext';
import AdminDashboard from './Components/AdminPortal/AdminDashboard';
import TeacherDashboard from './Components/TeacherPortal/TeacherCompo';
import StudentDashboard from './Components/StudentPortal/StudentCompo';
import ResetPWD from './Components/Login/forgot/Resetpwd';





const App = () => {
  return (
    <BrowserRouter>
      <CourseProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/LoginForm/*" element={<LoginForm />} />
          <Route path="/SignupForm/*" element={<SignupForm />} />
          <Route path="/ForgotPassword/*" element={<ForgotPassword />} />
          <Route path="/DonationForm/*" element={<DonationForm />} />
          <Route path="/AdminDashboard/*" element={<AdminDashboard />} />
          <Route path="/TeacherDashboard/*" element={<TeacherDashboard />} />
          <Route path='/StudentDashboard/*' element={<StudentDashboard />} />
          <Route path='/ResetPwd/*' element={<ResetPWD />} />
        </Routes>
      </CourseProvider>
    </BrowserRouter>
  );
};

export default App;
